import React, {
    memo,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';
import { Link, navigate } from 'gatsby';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import localization from '../../localization';
import SEO from '../seo';
import * as style from './index.module.scss';
import config from '../../config';
import Layout from '../Layout';
import { useAppSelector } from '../../hooks';

const isBrowser = typeof window !== 'undefined';

interface Props {
    location: any;
    language: string;
}

const ForgotPassword = ({ location, language }: Props) => {
    // Redux
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const isSigningIn = useAppSelector((state) => state.global.isSigningIn);
    const user = useAppSelector((state) => state.global.user);
    const [recaptcha, setRecaptcha] = useState('');
    const [isFinished, setIsFinished] = useState(false);
    const recaptchaRef = useRef();
    const refIsRemoved = useRef(false);

    // Form values
    const [email, setEmail] = useState('');
    const inputEmailRef = useRef();

    // Logic for resetting password
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [resetPasswordError, setResetPasswordError] = useState<null | number>(
        null
    );

    const onRecaptchaChange = (val: any) => {
        setRecaptcha(val);
    };

    const onRecaptchaExpired = () => {
        setRecaptcha('');
    };

    useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    useEffect(() => {
        if (resetPasswordError != null) {
            setRecaptcha('');
            if (recaptchaRef && recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
        }
    }, [resetPasswordError]);

    useEffect(() => {
        if (inputEmailRef.current) {
            inputEmailRef.current.focus();
        }
    }, []);

    const isEmailVerified =
        user?.isEmailVerified != null && user?.isEmailVerified === true;
    const hasUser = user != null;

    useLayoutEffect(() => {
        if (isSignedIn && hasUser && !isSigningIn) {
            if (!isEmailVerified) {
                console.log('Redirect to /email-verification');
                navigate(`/${language}/email-verification`);
            } else {
                // console.log(`Redirect to /app/dashboard`)
                navigate(`/account/overview`);
            }
        }
    }, [hasUser, isSigningIn, isSignedIn, isEmailVerified, language]);

    const onSubmitForm = async (e: any) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setIsResettingPassword(true);
        try {
            await axios.post(
                `${config.apiBaseUrl}/account/forgotpassword?email=${email}&recaptcha=${recaptcha}`
            );

            setIsFinished(true);
        } catch (err: any) {
            setResetPasswordError(1);
        }

        setIsResettingPassword(false);
    };

    const isValidForm =
        email.length > 2 && email.indexOf('@') > 0 && recaptcha.length > 1;

    const renderError = (error: null | number) => {
        if (error === null) {
            return null;
        }
        return <>{localization[language].app.genericError}</>;
    };

    if (!isBrowser) {
        return (
            <>
                <SEO
                    title={localization[language].app.forgotPassword}
                    description={localization[language].app.forgotPassword}
                />
                <Layout>
                    <div className={style.signUpPage}></div>
                </Layout>
            </>
        );
    }

    if (isFinished) {
        return (
            <>
                <SEO
                    title={localization[language].app.forgotPassword}
                    description={localization[language].app.forgotPassword}
                />
                <Layout>
                    <div className={style.signUpPage}>
                        <form onSubmit={onSubmitForm}>
                            <h2>{localization[language].app.forgotPassword}</h2>
                            <div className={style.card}>
                                {localization[language].app.forgotEmailSent}
                            </div>
                        </form>
                        <div className={style.otherActions}>
                            <Link className='btn text large' to={`/`}>
                                {localization[language].form.labelFrontPage}
                            </Link>
                        </div>
                    </div>
                </Layout>
            </>
        );
    }

    return (
        <>
            <SEO
                title={localization[language].app.forgotPassword}
                description={localization[language].app.forgotPassword}
            />
            <Layout>
                <div className={style.signUpPage}>
                    <form onSubmit={onSubmitForm}>
                        <h2>{localization[language].app.forgotPassword}</h2>
                        <div className={style.card}>
                            <div className={style.item}>
                                <label className={style.label} htmlFor='email'>
                                    {localization[language].form.labelEmail}
                                </label>
                                <input
                                    type='email'
                                    id='email'
                                    name='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    ref={inputEmailRef}
                                />
                            </div>

                            {isBrowser && (
                                <div className={style.item}>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey='6LdJGy8aAAAAAN2VBM6dKFOwrur8BEvhde-4m4vI'
                                        onChange={onRecaptchaChange}
                                        onExpired={onRecaptchaExpired}
                                    />
                                </div>
                            )}

                            <p
                                className={classNames({
                                    [style.error]: true,
                                    [style.hasError]:
                                        resetPasswordError !== null &&
                                        !isResettingPassword,
                                })}
                            >
                                {renderError(resetPasswordError)}
                            </p>

                            <input
                                className='btn'
                                type='submit'
                                value={
                                    localization[language].password
                                        .labelContinue
                                }
                                onClick={onSubmitForm}
                                disabled={!isValidForm || isResettingPassword}
                            />
                        </div>
                    </form>
                    <div className={style.otherActions}>
                        {/* <Link
                            className='btn text large'
                            to={`/${language}/`}
                        >
                            {localization[language].register.title}
                        </Link> */}
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default memo(ForgotPassword);
